import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IArchiveRepository } from "./Iarchive.repository";
import { ApiResponse, SearchArchiveOptions, SearchArchiveResult } from "./models";

@Injectable()
export class ArchiveRepository implements IArchiveRepository {

	constructor(private httpClient: HttpClient) { }

	search(options: SearchArchiveOptions): Observable<ApiResponse<SearchArchiveResult>> {
		return this.httpClient.post<ApiResponse<SearchArchiveResult>>(environment.domainPatientAPI + "/api/archive/search", options);
	}

	getImage(id: string): Observable<Blob> {
		const params = new HttpParams().set('resourceId', id);
		return this.httpClient.get(environment.domainImageAPI + `/api/archive/GetImage`, { params, responseType: 'blob' });
	}

	getViewCase(id: string): Observable<Blob> {
		const params = new HttpParams().set('resourceId', id);
		return this.httpClient.get(environment.domainImageAPI + `/api/archive/GetViewCase`, { params, responseType: 'blob' });
	}
}

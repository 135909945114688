import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthCoreModule } from '@ortho-next/auth-core';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { AUTH_CONFIG } from './app.config';
import { APP_ROUTES } from './app.routes';
import { CaseRepository, FallbackComponent, HttpErrorInterceptor, LanguageService, LoaderService, MonitoringErrorHandler, MonitoringService, UserRepository } from './core';
import { ErrorsComponent } from './core/components/errors/errors.component';
import { CaseService, GuideService, UserService } from './services';


export function initApp(langSrv: LanguageService) {
	return (): Promise<any> => {
		return langSrv.loadLabels();
	};
};
export function initLocale(langSrv: LanguageService) {
	return (): Promise<any> => {
		return langSrv.initLocale(navigator.language);
	};
}


@NgModule({
	declarations: [
		AppComponent,
		ErrorsComponent,
		FallbackComponent
	],
	imports: [
		FormsModule,
		BrowserModule,
		AuthCoreModule.forRoot(AUTH_CONFIG),
		RouterModule.forRoot(APP_ROUTES),
		CommonModule,
		HttpClientModule
	],
	providers: [
		CookieService,
		MonitoringService,
		UserService,
		UserRepository,
		CaseService,
		CaseRepository,
		LoaderService,
		{
			provide: ErrorHandler,
			useClass: MonitoringErrorHandler
		},
		LanguageService,
		GuideService,
		{
			provide: APP_INITIALIZER, useFactory: initApp, deps: [LanguageService], multi: true
		},
		{
			provide: APP_INITIALIZER, useFactory: initLocale, deps: [LanguageService], multi: true
		},
		{
			provide: LOCALE_ID,
			useFactory: (langService: LanguageService) => langService.getCurrentLocale(),
			deps: [LanguageService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }

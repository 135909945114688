import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@ortho-next/auth-core';
import { SseService } from '@ortho-next/sse';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { LanguageService, MonitoringService, isGuid } from './core';
import { UserService } from './services';


/**
* Root application component
*/
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	constructor(
		private authService: AuthService,
		private langSrv: LanguageService,
		private usrSrv: UserService,
		private monitoringService: MonitoringService,
		private titleService: Title,
		private sse: SseService
	) {
		authService.isDoneLoading$.subscribe(() => {
			if (authService.hasValidToken()) {
				sse.connection(environment.sseAPI, () => firstValueFrom(authService.getAccessToken()));

				const userName = authService.userName;
				if (userName) {
					monitoringService.setAuthenticatedUserId(userName);
				}
			}
		});
		authService.onLogout$.subscribe(() => monitoringService.clearAuthenticatedUserId());

		authService
			.runInitialLoginSequence()
			.then(() => {
				usrSrv.isTlhexProduct().subscribe(value => {
					if (!value) {
						alert('YOU ARE NOT AUTHORIZED!');
						window.location.href = environment.patplanSite;
					}
				});

				const isArchive = window.location.pathname.includes('/archive');
				if (!isArchive) {
					const urlParams = new URLSearchParams(window.location.search);
					const caseGuid = urlParams.get('caseGuid');
					if (!isGuid(caseGuid)) {
						window.location.href = environment.patplanSite;
					}
				}

			});
	}

	ngOnInit() {
		this.titleService.setTitle(this.langSrv.getLabels().APP_MAIN_TITLE);
	}

}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, retry, throwError, timer } from "rxjs";


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
			retry({count: 3, delay: (error, retryCount) => error.status === 0 ? timer(retryCount * 1000) : throwError(() => error)}),
      catchError((err) => throwError(() => err))
		);
  }
}
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ITlhexReportRepository } from './Itlhex-report.repository';
import { ApiResponse, Bom, CaseBoneData, CorrectionTime, Prescription, Report, ReportDetail, Schedule, ScheduleConfiguration, ScheduleRequest, StrutBase } from './models';


@Injectable()
export class TlhexReportRepository implements ITlhexReportRepository {

  constructor(private httpClient: HttpClient) { }

  getCorrectionTimeList(): Observable<ApiResponse<CorrectionTime[]>> {
    return this.httpClient.get<ApiResponse<CorrectionTime[]>>(environment.domainTlhexAPI + `/api/Report/CorrectionTimeList`);
  }

  getSchedule(caseId: string, userId: string): Observable<ApiResponse<Schedule>> {
    return this.httpClient.get<ApiResponse<Schedule>>(environment.domainTlhexAPI + `/api/Report/Schedule?caseId=${caseId}&userId=${userId}`);
  }

  saveSchedule(caseId: string, schedule: ScheduleRequest): Observable<ApiResponse<Schedule>> {
    return this.httpClient.put<ApiResponse<Schedule>>(environment.domainTlhexAPI + `/api/Report/Schedule?caseId=${caseId}`, schedule);
  }

  getScheduleConfiguration(caseId: string, userId: string, residual: boolean): Observable<ApiResponse<ScheduleConfiguration>> {
    return this.httpClient.get<ApiResponse<ScheduleConfiguration>>(environment.domainTlhexAPI + `/api/Report/ScheduleConfiguration?caseId=${caseId}&userId=${userId}&residual=${residual}`);
  }

  saveLiabilityAcceptance(caseId: string, isAccepted: boolean): Observable<ApiResponse<void>> {
    return this.httpClient.put<ApiResponse<any>>(environment.domainTlhexAPI + `/api/Report/LiabilityAcceptance?caseId=${caseId}&isAccepted=${isAccepted}`, null);
  }

  getReport(caseId: string, userId: string): Observable<ApiResponse<Report>> {
    return this.httpClient.get<ApiResponse<Report>>(environment.domainTlhexAPI + `/api/Report/Report?caseId=${caseId}&userId=${userId}`);
  }

  getReportDetail(caseId: string, userId: string, adjustment: Date, bone: CaseBoneData): Observable<ApiResponse<ReportDetail>> {
    const params = new HttpParams()
      .set('caseId', caseId)
      .set('userId', userId)
      .set('adjustment', adjustment.toString())
      .set('type', bone.type)
      .set('side', bone.side)
      .set('reference', bone.reference);
    return this.httpClient.get<ApiResponse<ReportDetail>>(environment.domainTlhexAPI + `/api/Report/Detail`, { params });
  }

  getReportAdjust(caseId: string, userId: string, adjustment: Date): Observable<ApiResponse<Prescription>> {
    const params = new HttpParams()
      .set('caseId', caseId)
      .set('userId', userId)
      .set('adjustment', adjustment.toString())
    return this.httpClient.get<ApiResponse<Prescription>>(environment.domainTlhexAPI + `/api/Report/Adjust`, { params });
  }

  getProposedStrut(caseId: string, userId: string, adjustment: Date, number: number): Observable<ApiResponse<StrutBase>> {
    const params = new HttpParams()
      .set('caseId', caseId)
      .set('userId', userId)
      .set('adjustment', adjustment.toString())
      .set('number', number)
    return this.httpClient.get<ApiResponse<StrutBase>>(environment.domainTlhexAPI + `/api/Report/ProposeStrut`, { params });
  }

  adjust(caseId: string, userId: string, adjustment: Date, bone: CaseBoneData, struts?: StrutBase[]): Observable<ApiResponse<Prescription[]>> {
    const params = new HttpParams()
      .set('caseId', caseId)
      .set('userId', userId)
      .set('adjustment', adjustment.toString())
      .set('type', bone.type)
      .set('side', bone.side)
      .set('reference', bone.reference);
    return this.httpClient.patch<ApiResponse<Prescription[]>>(environment.domainTlhexAPI + `/api/Report/Adjust`, struts, { params });
  }

  getQRCode(caseId: string, userId: string): Observable<Blob> {
    const params = new HttpParams()
      .set('caseId', caseId)
      .set('userId', userId);
    return this.httpClient.get(environment.domainTlhexAPI + `/api/Report/QRCode`, { params, responseType: 'blob' });
  }

  reset(caseId: string): Observable<ApiResponse<Prescription[]>> {
    return this.httpClient.patch<ApiResponse<Prescription[]>>(environment.domainTlhexAPI + `/api/Report/Reset?caseId=${caseId}`, null);
  }

  optimize(caseId: string): Observable<ApiResponse<Prescription[]>> {
    return this.httpClient.patch<ApiResponse<Prescription[]>>(environment.domainTlhexAPI + `/api/Report/Optimize?caseId=${caseId}`, null);
  }

  getBOM(caseId: string, userId: string): Observable<ApiResponse<Bom>> {
    return this.httpClient.get<ApiResponse<Bom>>(environment.domainTlhexAPI + `/api/Report/BOM?caseId=${caseId}&userId=${userId}`);
  }
}

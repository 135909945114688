export interface Bom {
  struts: BomItem[];
  rings: BomItem[];
  additional: BomItem[];
  iWrench?: BomItem;
}

export interface BomItem {
  item: BomItemType;
  description: string;
  quantity: number;
  number: string;
}

export enum BomItemType {
  Strut = "Strut",
  ProximalRing = "ProximalRing",
  DistalRing = "DistalRing",
  IWrench = "IWrench"
}
import { Strut } from "./strut";


export interface StrutClick extends Strut {
  /**
   * Strut number (from 1 to 6).
   */
  number: number;
  /**
   * Strut direction.
   */
  direction: StrutDirectionType;
  /**
   * If true, the strut acute measure can change (usually some days before the limit that is when HasAcuteTochange is true.
   */
  canAcuteChange: boolean;
  /**
   * If true, the strut size can change (usually some days before the limit that is when HasStrutSizeTochange is true).
   */
  canSizeChange: boolean;
  /**
   * If true, the strut acute measure has to change before the next prescription step.
   */
  hasAcuteToChange: boolean;
  /**
   * If true, the strut size has to change before the next prescription step.
   */
  hasSizeToChange: boolean;
  /**
   * Click for each struts for a specific precription step.
   */
  click: number;

  /**
   * Manual turn for each struts for a specific precription step.
   */
  manualTurn: number;

  /**
   * Motor turn for each struts for a specific precription step.
   */
  motorTurn: number;

  /**
   * Screw displacement for each struts for a specific precription step.
   */
  displacement: number;

  /**
   * Rising/falling edge for each struts for a specific precription step.
   */
  risingEdge: number;
}

export enum StrutDirectionType {
  Undefined = "Undefined",
  Up = "Up",
  Down = "Down"
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Product } from '../core';
import { UserService } from './user.service';

export interface Guide {
	name: string;
	path: string;
	products: string[];
	labelKey: string
}

/**
* This service handles guide list according to user products.
*/
@Injectable()
export class GuideService {

	constructor(
		private http: HttpClient,
		private userSrv: UserService
	) { }

	/**
	* Get guide url
	*/
	getGuideUrl(path: string): string {
		return `${environment.guideSite.baseUrl}/${path}`;
	}

	/**
	* Get guide list filtered by user products
	*/
	getGuideList(): Observable<Guide[]> {
		return combineLatest([this.getUserProducts(), this.loadGuideList()]).pipe(
			map(([usrProds, guideList]) => guideList.filter(guide => this.isGuideValid(guide.products, usrProds))),
		);
	}

	/**
	* Check if guide is valid according to the user products
	*/
	private isGuideValid(guideProds: string[], usrProds: Product[]): boolean {
		return !guideProds || guideProds.length === 0 || guideProds.some(gp => usrProds.some(p => p.code == gp));
	}

	/**
	* Get current user products
	*/
	private getUserProducts(): Observable<Product[]> {
		return this.userSrv.getCurrentUser().pipe(
			filter(user => !!user.products),
			map(user => user.products)
		);
	}

	/**
	* Load guide list
	*/
	private loadGuideList(): Observable<Guide[]> {
		return this.http.get<Guide[]>(environment.guideSite.list).pipe(
			filter(list => !!list && list.length > 0)
		);
	}

}

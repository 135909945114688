import { TranslationTypeEnum } from "./deformity-view-data";


/**
* Mounting view data model
*/
export interface MountingViewData {
	angle: number;
	angulation: MountingAngleTypeEnum;
	displacement: number;
	translation: TranslationTypeEnum;

}

export enum MountingAngleTypeEnum {
	Undefined = "Undefined",
	AnteriorDown = "AnteriorDown",
	AnteriorUp = "AnteriorUp",
	MedialDown = 'MedialDown',
	MedialUp = 'MedialUp'
}

import { MountingParametersBase } from "./mounting-parameters-request";
import { Strut } from "./strut";
import { ViewImages } from "./view-images";


/**
* Mounting parameters model
*/
export interface MountingParameters extends MountingParametersBase {
	frameID: FrameIDTypeEnum;
	struts?: Strut[];
	images: ViewImages;
	imagesEOC: ViewImages;
	strutsEOC?: Strut[];
}

export enum FrameIDTypeEnum {
	A = "A",
	B = "B",
	C = "C",
	D = "D",
	E = "E",
	F = "F"
}

export const FRAME_LIST: FrameIDTypeEnum[] = Object.values(FrameIDTypeEnum);

export enum ReferenceRingPositionRelativeToEnumType {
	DeformityApex = "DeformityApex",
	Osteotomy_FractureLevel = "Osteotomy_FractureLevel"
}

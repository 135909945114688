/**
 * Model to get schedule data.
 */
export interface Schedule extends ScheduleBase {
	/**
	 * Schedule event.
	 */
	scheduleEvent: ScheduleEvent;
}

export interface ScheduleEvent {
	firstStage: StrutChange[];
	secondStage?: StrutChange[];
	possibleIssue: StrutChangePossibleIssues[];
}

export interface StrutChange {
	acuteChanges: number;
	strutChanges: number;
}

export interface StrutChangePossibleIssues {
	outOfRange: boolean;
	impingement: boolean;
}

export interface ScheduleBase {
	/**
	 * ID correction times.
	 */
	correctionTimes: number[];
	/**
	 * Apply lengthening and shortening first.
	 */
	lengthShortFirst: boolean;
	/**
	 * Surgery date.
	 */
	surgeryDate: Date;
	/**
	 * Latency days before begin treatment.
	 */
	latencyDays: number;
	/**
	 * Prescription note.
	 */
	note?: string;
	/**
	 * Contains information about the treatment velocity or Residual stage in case of 2 stage correction.
	 */
	allOrResidualStage: ScheduleStage;
	/**
	 * Contains information about the lengthening shortening treatment velocity (only for 2 stage correction).
	 */
	lengthShortStage: LengthShortStage;
}

export interface ScheduleStage {
	/**
	 * Calculate by options dictionary (DailyCorrectionRate,AngularMaxSpeed,RotateMaxSpeed,DaysOfCorrection).
	 */
	calculateBy: CalculateOptionType;
	/**
	 * Daily Correction Rate speed (mm/day).
	 */
	dailyRate: number;
	/**
	 * Angular Max Speed (deg/day).
	 */
	angularSpeed: number;
	/**
	 * Rotate Max Speed (deg/day).
	 */
	rotarySpeed: number;
	/**
	 * Total days of correction value, when "CalculateBy" is "DaysOfCorrection" type.
	 */
	totalDays: number;
}

export interface LengthShortStage {
	/**
	 * Calculate by options dictionary (DailyCorrectionRate,AngularMaxSpeed,RotateMaxSpeed,DaysOfCorrection).
	 */
	calculateBy: CalculateOptionType;
	/**
	 * Daily Correction Rate speed (mm/day).
	 */
	dailyRate: number;
	/**
	 * Total days of correction value, when "CalculateBy" is "DaysOfCorrection" type.
	 */
	totalDays: number;
}

export enum CalculateOptionType {
	DailyCorrectionRate = "DailyCorrectionRate",
	AngularMaxSpeed = "AngularMaxSpeed",
	RotateMaxSpeed = "RotateMaxSpeed",
	DaysOfCorrection = "DaysOfCorrection"
}

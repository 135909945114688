import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, first, map, switchMap, tap } from "rxjs/operators";
import { CaseRepository, Plan } from "../core";
import { BaseService } from "./base.service";
import { UserService } from "./user.service";


/**
* This service handles the case data.
*/
@Injectable()
export class CaseService extends BaseService {

	private _currentCase: BehaviorSubject<Plan> = new BehaviorSubject<Plan>(null);
	private _isReadOnly: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	constructor(
		private caseRepo: CaseRepository,
		private userSrv: UserService
	) {
		super();
	}

	/**
	* Get current case.
	*/
	getCurrentCase(): Observable<Plan> {
		return this._currentCase.asObservable().pipe(first(_case => !!_case));
	}

	/**
	* Load case by id.
	*/
	loadCase(id: string): Observable<Plan> {
		return this.getCase(id).pipe(filter(plan => !!plan), tap(plan => this._currentCase.next(plan)));
	}

	/**
	* Get case by id
	* @param {string} id Case GUID
	*/
	private getCase(id: string): Observable<Plan> {
		return this.caseRepo.getCase(id).pipe(
			map(response => this.handleApiResponse(response)),
		);
	}

	/**
	* Check if is read-only case.
	*/
	isReadOnly(): Observable<boolean | null> {
		if (this._isReadOnly.value === null) {
			return combineLatest([
				this.getCurrentCase(),
				this.userSrv.getCurrentUser()
			]).pipe(
				map(([_case, _user]) => _case.userGuid !== _user.id),
				tap(res => this._isReadOnly.next(res)),
				switchMap(() => this._isReadOnly.asObservable())
			);
		}
		return this._isReadOnly.asObservable();
	}

	/**
	* Set case as read-only.
	*/
	setReadOnly(): void {
		this._isReadOnly.next(true);
	}

	/**
	* Get published plan with same frame of input plan.
	* @param {string} id Case GUID
	*/
	getPublishedPlan(id: string): Observable<Plan> {
		return this.caseRepo.getPublishedPlan(id).pipe(
			map(response => this.handleApiResponse(response)),
		);
	}

}

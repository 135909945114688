import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ICaseRepository } from "./Icase.repository";
import { ApiResponse, Plan } from "./models";

@Injectable()
export class CaseRepository implements ICaseRepository {

	constructor(private httpClient: HttpClient) { }

	getCase(id: string): Observable<ApiResponse<Plan>> {
		const params = new HttpParams().set('id', id);
		return this.httpClient.get<ApiResponse<Plan>>(environment.domainPatientAPI + "/api/cases/getcase", { params });
	}

	getPublishedPlan(id: string): Observable<ApiResponse<Plan>> {
		const params = new HttpParams().set('id', id);
		return this.httpClient.get<ApiResponse<Plan>>(environment.domainPatientAPI + "/api/cases/PublishedCase", { params });
	}
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ITlhexTreatmentPlanRepository } from './Itlhex-treatment-plan.repository';
import { ApiResponse, CaseBoneData, DeformityParameters, DeformityParametersRequest, FrameIDTypeEnum, MountingMatrices, MountingParameters, MountingParametersRequest, MountingParametersRequestBase, MountingSaveResponse, PlanFrameRequest, ScheduleEvent, TlhexStatusTypeEnum, ViewImages } from './models';

@Injectable()
export class TlhexTreatmentPlanRepository implements ITlhexTreatmentPlanRepository {

  constructor(private httpClient: HttpClient) { }

  getStatus(caseId: string, userId: string): Observable<ApiResponse<TlhexStatusTypeEnum>> {
    return this.httpClient.get<ApiResponse<TlhexStatusTypeEnum>>(environment.domainTlhexAPI + `/api/TreatmentPlan/Status?caseId=${caseId}&userId=${userId}`);
  }

  saveDeformityParameters(caseId: string, patientId: string, deformity: DeformityParametersRequest, hasIWrench?: boolean): Observable<ApiResponse<ViewImages>> {
    let params = new HttpParams().set('caseId', caseId).set('patientId', patientId);
    if (hasIWrench) params = params.set('hasIWrench', hasIWrench);
    return this.httpClient.put<ApiResponse<ViewImages>>(environment.domainTlhexAPI + `/api/TreatmentPlan/DeformityParameters`, deformity, { params });
  }

  getDeformityParameters(caseId: string, userId: string): Observable<ApiResponse<DeformityParameters>> {
    return this.httpClient.get<ApiResponse<DeformityParameters>>(environment.domainTlhexAPI + `/api/TreatmentPlan/DeformityParameters?caseId=${caseId}&userId=${userId}`);
  }

  getEOCDeformityParameters(caseId: string, userId: string): Observable<ApiResponse<DeformityParameters>> {
    return this.httpClient.get<ApiResponse<DeformityParameters>>(environment.domainTlhexAPI + `/api/TreatmentPlan/EOCDeformityParameters?caseId=${caseId}&user=${userId}`);
  }

  saveEOCDeformityParameters(caseId: string, patientId: string, deformity: DeformityParametersRequest, hasIWrench?: boolean): Observable<ApiResponse<ViewImages>> {
    let params = new HttpParams().set('caseId', caseId).set('patientId', patientId);
    if (hasIWrench) params = params.set('hasIWrench', hasIWrench);
    return this.httpClient.put<ApiResponse<ViewImages>>(environment.domainTlhexAPI + `/api/TreatmentPlan/EOCDeformityParameters`, deformity, { params });
  }

  getMountingParameters(caseId: string, userId: string): Observable<ApiResponse<MountingParameters>> {
    return this.httpClient.get<ApiResponse<MountingParameters>>(environment.domainTlhexAPI + `/api/TreatmentPlan/MountingParameters?caseId=${caseId}&userId=${userId}`);
  }

  saveMountingParameters(caseId: string, mounting: MountingParametersRequest): Observable<ApiResponse<MountingSaveResponse>> {
    return this.httpClient.put<ApiResponse<MountingSaveResponse>>(environment.domainTlhexAPI + `/api/TreatmentPlan/MountingParameters?caseId=${caseId}`, mounting);
  }

  checkNoDeformityToCorrect(caseId: string, user: string): Observable<ApiResponse<boolean>> {
    return this.httpClient.get<ApiResponse<boolean>>(environment.domainTlhexAPI + `/api/TreatmentPlan/NoDeformityToCorrect?caseId=${caseId}&user=${user}`);
  }

  getUsedFrameIDs(caseId: string, patientId: string, userId: string): Observable<ApiResponse<FrameIDTypeEnum[]>> {
    return this.httpClient.get<ApiResponse<FrameIDTypeEnum[]>>(environment.domainTlhexAPI + `/api/TreatmentPlan/UsedFrameIDs?caseId=${caseId}&patientId=${patientId}&userId=${userId}`);
  }

  calculatePlanFrame(caseId: string, planFrameRequest: PlanFrameRequest): Observable<ApiResponse<ScheduleEvent>> {
    return this.httpClient.post<ApiResponse<ScheduleEvent>>(environment.domainTlhexAPI + `/api/TreatmentPlan/PlanFrame?caseId=${caseId}`, planFrameRequest);
  }

  getRingMatrices(caseId: string, bone: CaseBoneData, mounting: MountingParametersRequestBase): Observable<ApiResponse<MountingMatrices>> {
    const params = new HttpParams()
      .set('caseId', caseId)
      .set('type', bone.type)
      .set('side', bone.side)
      .set('reference', bone.reference);
    return this.httpClient.post<ApiResponse<MountingMatrices>>(environment.domainTlhexAPI + `/api/TreatmentPlan/MatricesFromStruts`, mounting, { params });
  }
}

import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';


/**
 * This service handles lables and validation messages.
 */
@Injectable()
export class LanguageService {

	private localeId: string;
	private _labels: any = {};
	private _validationMsg: any = {};

	constructor(private http: HttpClient) { }

	/**
	* Get labels
	*/
	getLabels(): any {
		return this._labels;
	}

	/**
	* Get label
	*/
	getLabel(id: string): string {
		return this._labels[id];
	}


	/**
	* Load labels and validation messages
	*/
	loadLabels(): Promise<any> {
		return firstValueFrom(
			combineLatest([this.http.get("assets/labels.json"), this.http.get("assets/validation-messages.json")]).pipe(
				tap(([labels, validationMsg]) => {
					this._labels = labels;
					this._validationMsg = validationMsg
				}))
		);
	}

	/**
	* Get validation messages
	*/
	getValidationMessages(): any {
		return this._validationMsg;
	}

	/**
	* Check if a mobile device.
	*/
	get isMobileDevice(): boolean {
		return !!navigator.userAgent && navigator.userAgent.includes('Mobile');
	}

	/**
	* Set locale and load angular locales file js
	*/
	initLocale(localName: string): Promise<any> {
		console.log('Loading locales: ' + localName);
		const locale = localName?.split('-')[0];
		return import(
			/* webpackInclude: /\.mjs$/ */
			`/node_modules/@angular/common/locales/${locale}.mjs`
		).then(locale => {
			this.localeId = localName;
			registerLocaleData(locale.default);
		}).catch(() => this.localeId = 'en-US');
	}

	/**
	* Get current locale
	*/
	getCurrentLocale(): string {
		return this.localeId;
	}

}

/**
* Strut model
*/
export interface Strut extends StrutBase {
	proximalError: boolean;
	distalError: boolean;
	rangeError: StrutRangeErrorTypeEnum;
}

export interface StrutBase {
	total: number;
	size: StrutSizeTypeEnum;
	acute: number;
	gradual: number;
}

export enum StrutSizeTypeEnum {
	Short = "Short",
	Medium = "Medium",
	Long = "Long",
	UltraShort = 'UltraShort'
}

export enum StrutRangeErrorTypeEnum {
	InRange = "InRange",
	OverRange = "OverRange",
	UnderRange = "UnderRange"
}

import { TranslationTypeEnum } from "./deformity-view-data";

/**
* Axial deformity model
*/
export interface AxialDeformity {
	angle: number;
	rotation: RotationTypeEnum;
	displacement: number;
	translation: TranslationTypeEnum;
}

export enum RotationTypeEnum {
	Undefined = "Undefined",
	Internal = "Internal",
	Zero = "Zero",
	External = 'External'
}

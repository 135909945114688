/**
* Convert string in decimal number
*/
export function convertToDecimal(value: string): number {
	return /^([-+])?(\d+([.,]\d+)?)$/.test(value) ? parseFloat(value.replace(",", ".")) : null;
}

/**
* Trunc decimal number with two decimal digits
*/
export function truncDecimal(value: number): string {
	const decimal: string = value != null ? value.toString() : null;
	if (/^(\d+[.]\d+)$/.test(decimal)) {
		return decimal.substring(0, decimal.indexOf(".") + 2);
	} else if (/^(\d+,\d+)$/.test(decimal)) {
		return decimal.substring(0, decimal.indexOf(",") + 2);
	} else {
		return decimal;
	}
}
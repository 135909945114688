/**
* Deformity view data model
*/
export interface DeformityViewData {
	angle: number;
	angulation: AngularDeformityTypeEnum;
	displacement: number;
	translation: TranslationTypeEnum;

}

export enum AngularDeformityTypeEnum {
	Undefined = "Undefined",
	Varus = "Varus",
	Valgus = "Valgus",
	ApexPosterior = 'ApexPosterior',
	ApexAnterior = 'ApexAnterior',
	Zero = 'Zero'
}

export enum TranslationTypeEnum {
	Undefined = "Undefined",
	Anterior = "Anterior",
	Posterior = "Posterior",
	Medial = 'Medial',
	Lateral = 'Lateral',
	Short = 'Short',
	Long = 'Long',
	Zero = 'Zero'
}

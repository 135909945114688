import { Component } from '@angular/core';
import { LanguageService } from '../../language.service';


/**
* Errors page component to handle generic errors 
*/
@Component({
	selector: 'app-errors',
	templateUrl: './errors.component.html'
})
export class ErrorsComponent {

	errMsg: string;
	backToHomeMsg: string;

	constructor(private langSrv: LanguageService) {
		const labels = this.langSrv.getLabels();
		this.errMsg = labels['ERROR_MESSAGE_PAGE'];
		this.backToHomeMsg = labels['BACK_TO_HOME'];
	}

}

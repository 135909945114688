import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUserRepository } from './Iuser.repository';
import { ApiResponse, UserProfile } from './models';

@Injectable()
export class UserRepository implements IUserRepository {

	constructor(private httpClient: HttpClient) { }

	getUserProfile(): Observable<ApiResponse<UserProfile>> {
		return this.httpClient.get<ApiResponse<UserProfile>>(environment.domainUserAPI + "/api/users/GetUserProfile");
	}

}

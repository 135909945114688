import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { IImageRepository } from "./Iimage.repository";
import { ApiResponse, Image } from "./models";


@Injectable()
export class ImageRepository implements IImageRepository {

	constructor(private httpClient: HttpClient) { }

	getImageDetail(image: string, user?: string): Observable<ApiResponse<Image>> {
		const userParam = !!user ? `&user=${user}` : '';
		return this.httpClient.get<ApiResponse<Image>>(environment.domainImageAPI + `/api/Images/Detail?image=${image}${userParam}`);
	}

}

/**
 * Product model
 */
export interface Product {
	code: string;
	type: ProductTypeEnum;
	demo: boolean;
	version: string;
}

export enum ProductTypeEnum {
	Fitbone = "FITBONE",
	JPS = "JPS",
	TLHex = "TL-HEX",
	Platform = 'PLATFORM',
	MyHexPlan = 'MYHEXPLAN'
}

/**
* Wrapper response model of API
*/
export interface ApiResponse<T> {
  statusCode: ApiResponseStatusCode;
  errorMessage: string;
  result?: T;
}

export enum ApiResponseStatusCode {
  Success = "Success",
  ItemNotFound = "ItemNotFound",
  PatientNumberNotUnique = "PatientNumberNotUnique",
  CaseNumberNotUnique = "CaseNumberNotUnique",
  GenericError = "GenericError"
}

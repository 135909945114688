/**
* Ring size model
*/
export interface RingSize {
	id: number;
	ringType: RingTypeEnum;
	description: string;
	catalogNumber: string;
	ringTypeDesc?: string;
}

export enum RingTypeEnum {
	FullRing = "FullRing",
	FullRing58Plus38 = "FullRing58Plus38",
	OpenAnteriorRing = "OpenAnteriorRing",
	OpenMedialRing = 'OpenMedialRing',
	OpenPosteriorRing = 'OpenPosteriorRing',
	FootPlate = 'FootPlate',
}


/**
 * Get description of ring type.
 */
export function ringTypeDesc(ringType: RingTypeEnum, isFoot: boolean): string {
	switch (ringType) {
		case RingTypeEnum.FootPlate: return 'Foot Plate';
		case RingTypeEnum.FullRing: return 'Full Ring';
		case RingTypeEnum.FullRing58Plus38: return 'Full Ring (5/8 plus 3/8)';
		case RingTypeEnum.OpenAnteriorRing: return isFoot ? '5/8 Open Dorsal Ring' : '5/8 Open Anteriorly Ring';
		case RingTypeEnum.OpenMedialRing: return '5/8 Open Medially Ring';
		case RingTypeEnum.OpenPosteriorRing: return isFoot ? '5/8 Open Plantar Ring' : '5/8 Open Posteriorly Ring';
		default: return '';
	}
}

/**
 * Get ring description. 
 */
export function ringDesc(ring: RingSize, isFoot: boolean): string {
	return `${ringTypeDesc(ring?.ringType, isFoot)} - ${ring?.description}`;
}

import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


/**
* This service handles help modal.
*/
export class HelpService {

	private fileNameList: any;
	private _isLoaded: boolean;

	constructor(
		protected http: HttpClient,
		protected sanitizer: DomSanitizer
	) { }

	/**
	* Check if help file name list is loaded
	*/
	get isLoaded(): boolean {
		return this._isLoaded;
	}

	/**
	* Load help file name list
	*/
	loadFileNames(): Observable<any> {
		return this.http.get(environment.helpSite.fileNames).pipe(tap(list => {
			this.fileNameList = list;
			this._isLoaded = true;
		}));
	}

	/**
	* Get safe url of help
	*/
	get safeUrl(): SafeUrl {
		const url = this.helpUrl;
		return url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : null;
	}

	private get helpUrl(): string {
		const fileName = this.fileNameList ? this.fileNameList[this.fileKey] : null;
		return fileName ? `${environment.helpSite.baseUrl}/${fileName}.html` : null;
	}

	protected get fileKey(): string {
		return ``;
	}

}

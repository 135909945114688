import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ITlhexIntegrationRepository } from './Itlhex-integration.repository';
import { ApiResponse, PortalPageEnum } from './models';

@Injectable()
export class TlhexIntegrationRepository implements ITlhexIntegrationRepository {

	constructor(private httpClient: HttpClient) { }

	getTreatmentSupportPortalUrl(page: PortalPageEnum, patientId?: string, planId?: string): Observable<ApiResponse<string>> {
		const params = new HttpParams()
			.set('page', page)
			.set('patientId', patientId)
			.set('caseId', planId)
		return this.httpClient.get<ApiResponse<string>>(environment.domainTlhexAPI + `/api/Integration/PrepareTreatmentSupportPortalUrl`, { params });
	}
}

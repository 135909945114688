import { toPng } from "html-to-image";
import html2canvas from "html2canvas";
import jsPDF, { ImageCompression } from "jspdf";
import { Observable, from, map } from "rxjs";


/**
* PDF permissions accessible by any user.
*/
const PDF_USER_PERMISSIONS = ['print' as const];

const PDF_COMPRESSION: ImageCompression = 'MEDIUM';
const IMG_WIDTH = 297;
const IMG_HEIGHT = 210;

/**
 * Generate pdf with only one page from html.
 * @param html Html of pdf content
 * @param fileName Pdf file name
 */
export function generateSinglePdf(html: HTMLElement, fileName: string): Observable<jsPDF> {
	const pdf = createEmptyPdf();
	const printPromise = convertHtml(html);
	return from(printPromise).pipe(
		map(content => {
			setPage(pdf, 1, content);
			pdf.save(fileName);
			return pdf;
		})
	);
}

/**
 * Generate a random password.
 */
function genPdfPassword(): string {
	return Buffer.from(crypto.getRandomValues(new Uint16Array(16))).toString('base64');
}

export function createEmptyPdf(): jsPDF {
	const pdfOwnerPassword = genPdfPassword();
	return new jsPDF({
		compress: true,
		format: 'a4',
		orientation: 'l',
		unit: 'mm',
		encryption: { userPermissions: PDF_USER_PERMISSIONS, ownerPassword: pdfOwnerPassword }
	});
}

export function convertHtml(html: HTMLElement): Promise<string> {
	if (isSafari()) return html2canvas(html, {
		allowTaint: true,
		scale: 2,
		width: 1348,
		height: 952,
		useCORS: true,
	}).then(canvas => canvas.toDataURL('image/png'))
	else
		return toPng(html, { width: 1348, height: 952, pixelRatio: 2 });
}

export function setPage(pdf: jsPDF, index: number, content: string): jsPDF {
	if (index > 1) pdf.addPage();
	pdf.setPage(index + 2).addImage(content, 'PNG', 0, 0, IMG_WIDTH, IMG_HEIGHT, undefined, PDF_COMPRESSION);
	return pdf;
}

function isSafari(): boolean {
	const userAgent = navigator.userAgent;
	let isSafari: boolean;
	if ((window as any).chrome && userAgent.match(/chrome|chromium|crios/i))
		isSafari = false;
	else if (userAgent.match(/firefox|fxios/i))
		isSafari = false;
	else if (userAgent.match(/safari/i))
		isSafari = true;

	return isSafari;
}
/**
* This service handles navbar component.
*/
export abstract class NavbarService {

	/**
	 * Check if save icon is visible.
	 */
	abstract isSaveVisible(): boolean;

	/**
	 * Check if save icon is disabled.
	 */
	abstract isSaveDisabled(): boolean;

	/**
	 * Save model from navbar icon.
	 */
	abstract save?(): void;

	/**
	 * Open modal before application closing.
	 */
	abstract openClosingModal(): void;

}

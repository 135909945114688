import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IModelRepository } from './Imodel.repository';
import { ApiResponse, MountingSaveResponseBase, RayModel } from './models';


@Injectable()
export class ModelRepository implements IModelRepository {

	constructor(private httpClient: HttpClient) { }

	getTlhexModel(caseId: string, userId: string): Observable<ApiResponse<RayModel>> {
		const options = {
			params: { caseId, userId }
		};
		return this.httpClient.get<ApiResponse<RayModel>>(environment.domainTlhexAPI + '/api/model/get', options);
	}

	getRayModel(caseId: string, userId: string): Observable<ApiResponse<RayModel>> {
		const options = {
			params: { caseId, userId }
		};
		return this.httpClient.get<ApiResponse<RayModel>>(environment.domainRayAPI + '/api/model/get', options);
	}

	saveModel(model: RayModel): Observable<ApiResponse<void>> {
		return this.httpClient.post<ApiResponse<void>>(environment.domainTlhexAPI + '/api/model/save', model);
	}

	saveDeformityParameters(model: RayModel): Observable<ApiResponse<void>> {
		return this.httpClient.post<ApiResponse<void>>(environment.domainTlhexAPI + '/api/model/DeformityParameters', model);
	}

	saveMountingParameters(model: RayModel): Observable<ApiResponse<MountingSaveResponseBase | null>> {
		return this.httpClient.post<ApiResponse<MountingSaveResponseBase | null>>(environment.domainTlhexAPI + '/api/model/MountingParameters', model);
	}

}

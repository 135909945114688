import { StrutClick } from "./strut-click";


export interface Prescription {
	/**
	 * Indetify sequence treatment step.
	 */
	day: number;
	/**
	 * Date of current prescription step.
	 */
	adjustment: Date;
	/**
	 * Prescription stage type.
	 */
	stage: PrescriptionStageType;
	/**
	 * Strut set.
	 */
	struts: StrutClick[];
}


export enum PrescriptionStageType {
	// Correct All Deformities.
	All = "All",
	// Correct Axial Translation.
	AxialTranslation = "AxialTranslation",
	// Correct Residual Translation.
	ResidualTranslation = "ResidualTranslation"
}
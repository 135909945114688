import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ITlhexProductsRepository } from './ltlhex-products.repository';
import { ApiResponse, RingSize } from './models';

@Injectable()
export class TlhexProductsRepository implements ITlhexProductsRepository {

  constructor(private httpClient: HttpClient) { }

  getRingSizes(): Observable<ApiResponse<RingSize[]>> {
    return this.httpClient.get<ApiResponse<RingSize[]>>(environment.domainTlhexAPI + `/api/Products/RingSizes`);
  }
}

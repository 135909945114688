/**
* Convert date in string format according locale language
*/
export function convertToLocalDate(value: Date, UTCMode = true): string {
	if (!!value) {
		const date = new Date(value);
		const lang: string = navigator.language;
		const options = UTCMode ? { timeZone: 'UTC', numberingSystem: 'latn' } : { numberingSystem: 'latn' };
		const locale = new Intl.DateTimeFormat(lang, options).format(date);
		const raw = locale?.replace(/\u200f/g, ''); // remove rtl escape char
		return raw;
	}
	return null;
}

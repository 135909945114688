import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RingSize, TlhexProductsRepository } from '../core';
import { BaseService } from './base.service';

/**
* This service handles rings data
*/
@Injectable()
export class TlhexProductsService extends BaseService {

	constructor(private productsRepo: TlhexProductsRepository) {
		super();
	}

	/**
	* Get ring list.
	*/
	getRingSizes(): Observable<RingSize[]> {
		return this.productsRepo.getRingSizes().pipe(
			map(res => this.handleApiResponse(res))
		);
	}
}
